



































import Vue from "vue";
import mdui from "mdui";
import "codemirror/mode/stex/stex.js";
import { codemirror } from "vue-codemirror";
export default Vue.extend({
  name: "index",
  components: { codemirror },
  data() {
    return {
      text: "\\sum_{i=1}^n\\frac{1}{i^2}=\\frac{\\pi^2}6",
      scale: 80,
      dialog: new mdui.Dialog("#dia", {}),
      cmOptions: {
        // codemirror options
        tabSize: 2,
        line: true
        // more codemirror options, 更多 codemirror 的高级配置...
      }
    };
  },
  methods: {
    openDialog() {
      mdui.updateSliders();
    }
  },
  computed: {
    link() {
      return `https://www.zhihu.com/equation?tex=${encodeURIComponent(
        this.text
      )}&preview=true`;
    },
    getScacle() {
      return `${this.scale}px`;
    }
  }
});
